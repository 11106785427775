import React from 'react'
import logo from  '../../assets/predictor_logo.png';
import {Link} from 'react-router-dom' 
import doctor_group from  '../../assets/college_predictor_doctor_group.png';
import './PredictorBenefits.css'
const PredictorBenefits = () => {
    const redirect=()=>{
        window.location.href="/#/predictor";
    }
  return (
    <div className='container-fluid pt-5'>
        <div className='predictor_benefits_desktop_view'>
        <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-7 mt-5 pt-5'>
                <h1 className='blue-color'>NEET-PG College Predictor</h1>
            </div>
            <div className='col-md-3 text-right pt-5'> 
                {/* <img src={logo} alt="Logo" className="logo_predictor my-3 pt-5" style={{objectFit:"cover"}} /> */}
                </div>

        </div>
        <div className='row mt-3'>
            
            <div className='col-md-4'>
        <ul className="college_benefits" style={{fontWeight:"bold"}}>
            <li>Find the most optimal college for you.</li>
            <li>Identify best college to study.</li>
            <li>Search for the colleges you are interested in.</li>
            <li>Get course cutoffs and fees details.</li>
            <li>Get 1-to-1 personalised guidance.</li>
            <li> One Free trail</li>
          </ul>
          </div>
            <div className='col-md-4 text-left'>
            <ul className="college_benefits" style={{fontWeight:"bold"}}>
            <li>Easy access to all college Cut-offs.</li>
            <li>Open State Cut-offs.</li>
            <li>All Cut-offs.</li>
            <li>Deemed Cut-offs.</li>
            <li>Download Full Cut-offs Report.</li>
          </ul> 
            </div>
          </div>
          <div className='row'>
            
            <div className='col-md-5 text-center py-3'>
                <button style={{backgroundColor:"#f47523"}} className="text-white p-2 w-25 border-0 rounded" onClick={redirect}>Continue</button>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-5'>
                <img src={doctor_group} width={"100%"}  />
            </div>
          </div>
          </div>
          <div className='predictor_benefits_mobile_view mt-5 mx-4' >
            <div className='bg-white my-5' style={{borderRadius:"15px"}}>
                <div className='text-center'>
                 <img src={logo} alt="Logo" className="my-3 pt-5" width="122" height="121" style={{objectFit:"cover"}} />
                 </div>
                <h2 className='blue-color mt-2 display-4'><strong>NEET PG</strong></h2>
                <h2 className='blue-color mt-2 mb-4'><strong>College Predictor</strong></h2> 
        <ul className="collegebenefits justify-content-center">
            <li>Find the most optimal college for you.</li>
            <li>Identify best college to study.</li>
            <li>Search for the colleges you are interested in.</li>
            <li>Get course cutoffs and fees details.</li>
            <li>Get 1-to-1 personalised guidance.</li>
            <li>Easy access to all college Cut-offs.</li>
            <li>Open State Cut-offs.</li>
            <li>All Cut-offs.</li>
            <li>Deemed Cut-offs.</li>
            <li>Download Full Cut-offs Report.</li>
          </ul> 
        
          <div className='text-center'>
          <button style={{backgroundColor:"#f47523"}} className="text-white p-2 w-50 border-0 rounded mt-2 mb-2" onClick={redirect}><strong>Continue</strong></button>
          </div>
                 </div>
            </div>
            <div>
          </div>
          
    </div>
  )
}

export default PredictorBenefits