import React, { useState, useEffect } from "react";
import logo from  '../../assets/predictor_logo.png';
import doctor_group from  '../../assets/college_predictor_doctor_group.png';
import './login.css';
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SetAuthToken from "../../utils/SetAuthToken";

import {
    getAutocompleteColleges,
    hideMessage,
    setAuthUser,
    userLogin,
    userLogout,
    getCartStatus
} from "../../actions/Home";
import { getCollegeStates } from "../../actions/States";

const Login = () => {

  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const {
    message,
    error: reduxError,
    is_auth,
    auth_user,
    cart_counter
  } = useSelector((state) => ({
    message: state.home.message,
    error: state.home.error,
    is_auth: state.home.is_auth,
    auth_user: state.home.auth_user,
    cart_counter: state.home.cart_counter
  })); 

  useEffect(() => {
    if (localStorage.getItem("auth_user") && localStorage.getItem("auth_token")) {
        SetAuthToken(localStorage.getItem("auth_token"));
        dispatch(setAuthUser(JSON.parse(localStorage.getItem("auth_user"))));
        dispatch(getCartStatus());
    }

    dispatch(getCollegeStates());
  }, [dispatch]);

  const handleUserLogin = (e) => {
    e.preventDefault();

    const email = document.getElementById("mobile").value;
    const pwd = document.getElementById("password").value;

    if (email !== "" && pwd.length >= 8) {
      setError("");
      const formData = new FormData(document.getElementById("login_form"));
      formData.append("mobile", email);
      formData.append("password", pwd);
      dispatch(userLogin(formData));
      localStorage.setItem('otp_refresh',0)
    } else if (email.length !== 10) {
      setError("Enter a valid 10-digit number");
    } else {
      setError("Invalid Password or Phone");
    }
  };

  return (
    <div className="container-fluid background">
      <div className="row mt-2">
        <div className="col-md-6">
          <img src={logo} alt="Logo" className="logo_predictor my-3 pt-5" style={{objectFit:"cover"}} />
          <h1 className="fw-font blue-color heading-info">
            <strong>NEET-PG College<br/> Predictor</strong>
          </h1>
          <ul className="college_benefits">
            <li>Find the most optimal college for you.</li>
            <li>Identify best college to study.</li>
            <li>Search for the colleges you are interested in.</li>
            <li>Get course cutoffs and fees details.</li>
            <li>Get 1-to-1 personalised guidance.</li>
          </ul>
          <img src={doctor_group} className="doctors_group img-fluid" />
        </div>
        <div className="col-md-5 pt-5">
          <form id="login_form" className="mt-3" onSubmit={handleUserLogin}>
         
            <div className="mt-5 bg-white rounded-border pt-5 mb-4" >
            <img src={logo} alt="Logo" width={"100"} height="100" className="logo_predictor_mobile m-auto"  />
              <div>
                <h1 className="text-center font-weight-bold blue-color display-4">Hi Doctor</h1>
                <p className="text-center">Welcome to <span style={{ color: "#F47523" }}>Goo</span>Campus</p>
              </div>
              <div className="mt-3 p-5">
                <br />
                <label className="text-left">Phone Number</label>
                <input className="form-control border-color borderradius" type="text" name="mobile" id="mobile" placeholder="Enter 10 digit number" />
                <br />
                <label className="text-left">Password</label>
                <input className="form-control border-color borderradius" type="password" name="password" id="password" placeholder="Password" />
                <Link className="float-right mt-2" style={{ color: "#233974" }} to="/forgot_password">
                  <span>Forgot password?</span>
                </Link>
              </div>
              {error && <p className="text-danger text-center">{error}</p>}
              <div className="text-center">
                <button type="submit" className="btn bg-orange text-center w-50 text-white borderradius my-3">
                  <b>Login</b>
                </button>
              </div>
              <div className="text-center py-1 pb-5">
                <Link to="/register" className="blue-color" title="Register">
                  Don’t have an account? <span style={{ textDecoration: 'underline' }}>Create Account</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  );
};

export default Login;
