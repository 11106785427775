import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import server from "../utils/Server";
import SetAuthToken from "../utils/SetAuthToken";
import $ from "jquery";

export const GET_AUTO_COLLEGES_SUCCESS = "GET_AUTO_COLLEGES_SUCCESS";
export const GET_HOME_SUCCESS = "GET_HOME_SUCCESS";
export const MAIN_LOAD_TRUE = "MAIN_LOAD_TRUE";
export const MAIN_LOAD_FALSE = "MAIN_LOAD_FALSE";
export const SUB_LOAD_TRUE = "SUB_LOAD_TRUE";
export const SUB_LOAD_FALSE = "SUB_LOAD_FALSE";
export const DISPLAY_MESSAGE = "DISPLAY_MESSAGE";
export const DISPLAY_ERROR = "DISPLAY_ERROR";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const SUCCESS_STATUS = "SUCCESS_STATUS";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const UPDATE_CART_COUNT = "UPDATE_CART_COUNT";
export const GET_RESULT_SUCCESS = "GET_RESULT_SUCCESS";

export const getAutocompleteColleges = state_id => {
    return dispatch => {
        return server
            .get("/get_autocomplete_colleges" + state_id)
            .then(response => {
                const { data } = response;
                if (data.code === 200)
                    dispatch(getAutocompleteCollegesSuccess(data.colleges));
            });
    };
};

export const getHomeDetails = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.get("/get_home_details").then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (data.code === 200) dispatch(getHomeSuccess(data));
            dispatch(getAutocompleteCollegesSuccess(data.colleges));
        });
    };
};

export const registerUser = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/register", data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200) {
                    console.log('register_data',data)
                    dispatch(displayMessage(data.message));
                    localStorage.setItem("mobile",data.data.mobile)
                    // localStorage.setItem(
                    //     "auth_user",
                    //     JSON.stringify(data.data)
                    // );
                    var token = data.data.token;
                    localStorage.setItem("auth_token", token);
                    SetAuthToken(token);
                    dispatch(loginSuccess());
                    // dispatch(setAuthUser(data.data));
                    window.location.hash="/register_otp";
                } else dispatch(displayError(data.message));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError("Failed, try again later");
            });
    };
};

export const userLogin = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/login", data)
            .then(response => {
                console.log(response);
                const { data } = response;
               
                dispatch(mainLoadingFalse());
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem(
                        "auth_user",
                        JSON.stringify(data.data)
                    );
                    localStorage.setItem("auth_token", data.data.token);
                    SetAuthToken(data.data.token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(data.data.data));
                    window.location.hash="/get_otp";
                    // return<Redirect to="#/get_otp"/>
                    // alert("successfull");
                } else {
                    dispatch(displayError(data.message));
                }
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError("Failed, try again later");
            });
    };
};

export const userLogout = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server.post("/logout").then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (data.code === 200) {
                localStorage.removeItem("auth_token");
                localStorage.removeItem("auth_user");
                SetAuthToken("");
                dispatch(userLogoutSuccess());
            }
        });
    };
};

export const addRemoveFavourite = college_id => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/add_remove_favourite_college/" + college_id)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200) dispatch(displayMessage(data.message));
                else dispatch(displayError(data.error));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError("Failed, try again later");
            });
    };
};

export const updateProfile = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/update_profile", data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem(
                        "auth_user",
                        JSON.stringify(data.auth_user)
                    );
                    dispatch(setAuthUser(data.auth_user));
                } else dispatch(displayError(data.error));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError("Failed, try again later");
            });
    };
};

export const updatePassword = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/update_password", data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    $("#edit_pwd, #edit_new_pwd, #edit_cfrm_pwd").val("");
                } else dispatch(displayError(data.error));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError("Failed, try again later");
            });
    };
};

export const updateSettings = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/update_settings", data)
            .then(response => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    localStorage.setItem(
                        "auth_user",
                        JSON.stringify(data.auth_user)
                    );
                    dispatch(setAuthUser(data.auth_user));
                } else dispatch(displayError(data.error));
            })
            .catch(err => {
                dispatch(mainLoadingFalse());
                displayError("Failed, try again later");
            });
    };
};

// Send forgot password otp action
export const sendForgotPasswordOTP = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/send_forgot_password_otp", data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus("OTP sent"));
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Update new forgotten password action
export const updateNewPassword = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/update_new_password", data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    dispatch(successStatus("Password updated"));
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Add to cart
export const addRemoveCart = data => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/add_remove_cart", data)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    dispatch(updateCartCount(data.cart_count));
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get cart status
export const getCartStatus = () => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_cart_status")
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) {
                    dispatch(updateCartCount(data.cart_count));
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get cart details
export const getCartDetails = coupon => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_cart_details?coupon=" + coupon)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) {
                    dispatch(getCartSuccess(data.cart));
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Remove from cart
export const removeFromCart = plan_id => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .post("/remove_from_cart/" + plan_id)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) {
                    dispatch(displayMessage(data.message));
                    dispatch(updateCartCount(data.cart_count));
                    dispatch(getCartDetails());
                } else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

// Get result api
export const getNEETResult = roll_number => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return server
            .get("/get_pg_results/" + roll_number)
            .then(response => {
                dispatch(mainLoadingFalse());
                const { data } = response;
                if (data.code === 200) dispatch(getResultSuccess(data.result));
                else dispatch(displayError(data.error));
            })
            .catch(error => {
                dispatch(mainLoadingFalse());
                dispatch(displayError("Failed, try again later"));
            });
    };
};

export const getAutocompleteCollegesSuccess = colleges => {
    return {
        type: GET_AUTO_COLLEGES_SUCCESS,
        payload: {
            autocomplete_colleges: colleges
        }
    };
};

export const getHomeSuccess = data => {
    return {
        type: GET_HOME_SUCCESS,
        payload: {
            popular_colleges: data.popular_colleges,
            favourite_colleges: data.favourite_colleges
        }
    };
};

export const mainLoadingTrue = () => {
    return {
        type: MAIN_LOAD_TRUE
    };
};

export const mainLoadingFalse = () => {
    return {
        type: MAIN_LOAD_FALSE
    };
};

export const subLoaderTrue = () => {
    return {
        type: SUB_LOAD_TRUE
    };
};

export const subLoaderFalse = () => {
    return {
        type: SUB_LOAD_FALSE
    };
};

export const displayMessage = message => {
    return {
        type: DISPLAY_MESSAGE,
        payload: {
            message: message
        }
    };
};

export const displayError = error => {
    return {
        type: DISPLAY_ERROR,
        payload: {
            error: error
        }
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE
    };
};

export const setAuthUser = auth_user => {
    return {
        type: SET_AUTH_USER,
        payload: {
            auth_user: auth_user
        }
    };
};

export const loginSuccess = () => {
    return {
        type: LOGIN_SUCCESS
    };
};

export const userLogoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

export const saveCheckoutSuccess = order_id => {
    return {
        type: CHECKOUT_SUCCESS,
        payload: {
            order_id: order_id
        }
    };
};

export const successStatus = status => {
    return {
        type: SUCCESS_STATUS,
        payload: {
            status: status
        }
    };
};

export const getCartSuccess = cart => {
    return {
        type: GET_CART_SUCCESS,
        payload: {
            cart: cart
        }
    };
};

export const updateCartCount = count => {
    return {
        type: UPDATE_CART_COUNT,
        payload: {
            count: count
        }
    };
};

// Get result success action
export const getResultSuccess = result => {
    return {
        type: GET_RESULT_SUCCESS,
        payload: {
            result: result
        }
    };
};
