import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import './PredictorformPayment.css';
import {
    getAutocompleteColleges,
    hideMessage,
    setAuthUser,
    userLogin,
    userLogout,
    getCartStatus,
    mainLoadingTrue,
    mainLoadingFalse,
    displayMessage,
    
} from "../../actions/Home";
import { useDispatch } from "react-redux";
import server from '../../utils/Server';
import swal from 'sweetalert';
import $ from 'jquery';
import paymentTick from '../../assets/payment_tick.png'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const PredictorformPayment = () => {
    const [orderId, setOrderId] = useState(null);
    const dispatch = useDispatch();
    const [disabled,setDisabled]=useState(false);
      
  
    const loadRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    
    const handlePayment = async () => {
        setDisabled(true)
        const isScriptLoaded = await loadRazorpay();
        if (!isScriptLoaded) {
            alert('Razorpay SDK failed to load');
            return;
        }

        try {
            // Make a request to Laravel to create an order
            const { data } = await server.post('/create-order', { amount: 4999 });

            const options = {
                key: 'rzp_live_rCTtK3Eo7LqpiC', // Replace with your Razorpay Key ID
                amount: data.amount,    // Amount in paisa
                currency: 'INR',
                name: 'Goocampus Edu Solutions Pvt Ltd',
                description: 'Test Transaction',
                image: 'https://api.prod.goocampus.in/public/goocampus_temp_logo.png/',
                order_id: data.id,      // Razorpay Order ID returned from the Laravel backend
                handler: async function (response) {
                    const paymentDetails = {
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_signature: response.razorpay_signature,
                    };
         
                    
                    // Send the payment details to the server for verification
                    const verifyUrl = '/verify-payment';
                    var formData=new FormData();
                    formData.append('razorpay_signature',paymentDetails.razorpay_signature)
                    formData.append('razorpay_order_id',paymentDetails.razorpay_order_id)
                    formData.append('razorpay_payment_id',paymentDetails.razorpay_payment_id)
                    const { data } = await server.post(verifyUrl, formData);
                    
                    if (data.success) {
                    server.post(`/payment_update/${auth_user.id}`,formData)
                    .then((response) => {
                        if(response.data.code===200){
                            
                            // dispatch(displayMessage("payment successfull"));
                            
                            $('#paymentSuccessModal').modal('show');
    
                            // Redirect after a delay (optional)
                            // setTimeout(function() {
                            //     window.location.href = "/#/predictor_colleges";
                            // }, 3000);  // Delay for 3 seconds before redirect
                              
                       
                        }else{
                            setDisabled(false)
                            console.log('payment not updated');
                        }
                    })
                    } else {
                        window.location.reload()
                        alert('Payment verification failed');
                    }
                },
                prefill: {
                    name: auth_user.fname+" "+auth_user.lname,
                    email: auth_user.email,
                    contact: auth_user.mobile,
                },
                theme: {
                    color: '#3399cc',
                },
            };

            const razorpay = new window.Razorpay(options);
            razorpay.open();
        } catch (error) {
            setDisabled(false)
            console.error('Payment request error:', error);
        }
    };
    const {is_auth,auth_user,streams,levels}= useSelector((state) => ({
        is_auth: state.home.is_auth,
        auth_user: state.home.auth_user,
        streams: state.list.streams,
        levels: state.list.course_levels
      })); 
      const redirectPredictor=()=>{
        window.location.hash="/predictor_colleges"
      }
  return (
   <div className='container-fluid pt-5 mt-5 bg-white '>

<div className='container'>
    <div className='bg-white pt-5'>
        <h3>Hi <strong>{auth_user.fname}</strong>, please upgrade to download this</h3>
            <h3><strong>PDF</strong> and get access to <strong>Unlimited College Prediction</strong></h3>
       <br/>

    <div className='row text-center py-4 px-5' >
       
        <div className='col-md-12 pt-3'style={{background:"#FFEEE6" ,boxShadow:"16px 13px 16px 1px rgba(0, 0, 0, 0.05)",borderRadius:"15px"}}  >
            <h1 className="discount" style={{textAlign:"center",color:"#233974",fontSize:"100px"}}>50% off</h1>
            <p className="limited-offer" style={{color:"#000",fontSize:"30px"}}>Claim your limited-time offer:</p>
            <h2 className="price">
                <span className="current-price" style={{color:"#233974"}}>4999 INR</span> 
                <span className="original-price ml-2" style={{fontSize:"20px"}}>9999 INR</span>
            </h2>
            <p className="tax-info">Inclusive of all taxes</p>
            <button className="my-3 text-white w-50 border-0 rounded py-2" onClick={handlePayment} disabled={disabled} 
    
    style={{ backgroundColor: disabled ? "#ccc" : "#F47523",border:"0",borderRadius:"10px"  }}>UPGRADE</button>
        </div>
       
    </div>
    </div>
   
<div className="modal fade" id="paymentSuccessModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered"   role="document">
    <div className="modal-content text-center "style={{backgroundColor:"#FFEEE6"}}>
      <div className="text-center pt-5">
        <img className='mb-3' src={paymentTick} width={"75"}/>
        <h3 className="text-center blue-color" id="exampleModalLabel"> <strong>Payment Successful!</strong></h3>
       
      </div>
      <div className="modal-body">
You have now unlocked access to all available authority cutoffs and report download
      </div>
      <div className="py-3">
        <button type="button" onClick={redirectPredictor} style={{backgroundColor:"#F47523"}} className="text-white border-0 w-25 rounded" data-dismiss="modal"><strong>OK</strong></button>
      </div>
    </div>
  </div>
</div>


   </div>
   </div>
  )
}

export default PredictorformPayment