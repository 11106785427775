import React, { forwardRef } from 'react';

// Define inline styles for normal React components
const styles = {
  body: {
    fontFamily: 'Helvetica, Arial, sans-serif',
    padding: '20px',
    color: '#333',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  headerText: {
    color: '#012269',
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: '24px',
    color: '#012269',
    marginBottom: '10px',
  },
  detailsBox: {
    border: '2px solid #032269',
    padding: '20px',
    borderRadius: '12px',
    marginBottom: '20px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
  },
  col1: {
    width: '30%',
    fontWeight: 'bold',
  },
  col2: {
    width: '70%',
  },
  footer: {
    backgroundColor: '#fe672c',
    color: '#fff',
    textAlign: 'center',
    padding: '15px',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  tableHeader: {
    backgroundColor: '#032269',
    color: 'white',
    padding: '10px',
    textAlign: 'left',
  },
  tableCell: {
    border: '1px solid #032269',
    padding: '8px',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    marginBottom: '20px',
    width: '100%',
  },
};

// React component to render the college predictor details
const NeetPgCollegePredictor = forwardRef(({ data, cutoffColleges }, ref) => (
  <div style={styles.body} ref={ref}>
    {/* Header Section */}
    <div style={styles.header}>
      <div>
        <div style={styles.headerText}>
          GooCampus Edu Solutions Pvt Ltd
        </div>
        <div style={styles.headerText}>NEET-PG College Predictor</div>
      </div>
      <img
        src="https://api.prod.goocampus.in/public/goocmapus_temp_logo.png"
        alt="GooCampus Logo"
        style={{ width: '100px' }}
      />
    </div>

    {/* Candidate Details Section */}
    <div style={styles.detailsBox}>
      <div style={styles.sectionTitle}>Candidate Details</div>
      <div style={styles.row}>
        <div style={styles.col1}>Name</div>
        <div style={styles.col2}>: {data.name}</div>
      </div>
      <div style={styles.row}>
        <div style={styles.col1}>NEET-PG Rank</div>
        <div style={styles.col2}>: {data.rank}</div>
      </div>
      <div style={styles.row}>
        <div style={styles.col1}>Domicile</div>
        <div style={styles.col2}>: {data.state}</div>
      </div>
      <div style={styles.sectionTitle}>Counselling Details</div>
      <div style={styles.row}>
        <div style={styles.col1}>Authority</div>
        <div style={styles.col2}>: {data.authority}</div>
      </div>
      <div style={styles.row}>
        <div style={styles.col1}>State</div>
        <div style={styles.col2}>: {data.req_state}</div>
      </div>
      <div style={styles.row}>
        <div style={styles.col1}>Speciality</div>
        <div style={styles.col2}>: {data.course}</div>
      </div>
      <div style={styles.row}>
        <div style={styles.col1}>Budget</div>
        <div style={styles.col2}>: ₹{data.budget}</div>
      </div>
    </div>

    <img
      src="https://api.prod.goocampus.in/public/predictor_banner.png"
      alt="Predictor Banner"
      style={styles.image}
    />

    {/* Predicted Colleges Section */}
    <div style={styles.detailsBox}>
      <div style={styles.sectionTitle}>Your Predicted Colleges</div>
      <div style={styles.table}>
        <div style={styles.tableRow}>
          <div style={styles.tableHeader}>College Name</div>
          <div style={styles.tableHeader}>State</div>
          <div style={styles.tableHeader}>Specialty</div>
          <div style={styles.tableHeader}>Category</div>
          <div style={styles.tableHeader}>Quota</div>
          <div style={styles.tableHeader}>Cutoff Rank</div>
          <div style={styles.tableHeader}>Fees</div>
        </div>
        {cutoffColleges.map((college, index) => (
          <div style={styles.tableRow} key={index}>
            <div style={styles.tableCell}>{college.col_name}</div>
            <div style={styles.tableCell}>{college.state_name}</div>
            <div style={styles.tableCell}>{college.course_name}</div>
            <div style={styles.tableCell}>{college.category}</div>
            <div style={styles.tableCell}>{college.quota}</div>
            <div style={styles.tableCell}>{college.cutoff_rank}</div>
            <div style={styles.tableCell}>₹{college.course_fees.toLocaleString()}</div>
          </div>
        ))}
      </div>
    </div>

    {/* Footer */}
    <div style={styles.footer}>
      <div style={{ fontSize: '16px' }}>Contact Us: +91-XXXXXXXXXX</div>
      <div>© GooCampus Edu Solutions Pvt Ltd</div>
    </div>
  </div>
));

export default NeetPgCollegePredictor;
