import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/predictor_logo.png';
import doctor_group from '../../assets/college_predictor_doctor_group.png';
import './otp.css';
import server from '../../utils/Server';
import { displayMessage} from "../../actions/Home";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { useSelector } from "react-redux";
import SetAuthToken from "../../utils/SetAuthToken";

import {
    getAutocompleteColleges,
    hideMessage,
    setAuthUser,
    userLogin,
    userLogout,
    getCartStatus,
    loginSuccess,
} from "../../actions/Home";
const RegisterOTP = () => {
    const dispatch = useDispatch();
    const [otp, setOtp] = useState(new Array(4).fill(""));
    const [message, setMessage] = useState("");
    const [error_message,setErrorMessage]=useState('');
    const [mobile, setMobile] = useState("");
    const route = `${localStorage.getItem('route') || ''}`;
        
        // var auth_user=JSON.parse(localStorage.getItem('auth_user'))
       
    useEffect(() => {
        const mobile = localStorage.getItem('mobile');
    
        if (mobile && mobile) {
            setMobile(mobile);
        } else {
            setMessage("User data not found.");
        }
    }, []); // Runs once when the component mounts

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus on next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      
      // Join the array of OTP input values into a string, then convert to a number
      const otpNumber = parseInt(otp.join(""), 10);
  
      // Ensure the OTP is complete (4 digits in this case)
      if (otp.join("").length === 4) {
           // Logs the full OTP as a single number
          
         
          var formData=new FormData(document.getElementById('otp-verification-form'));
          formData.append('mobile',mobile);
          formData.append('otp',otpNumber);
          server.post('/verify_otp_sms',formData)
          .then((response)=>{
            
            if(response.data.code===200){
                console.log("user",response.data.user)
            setMessage(response.data.message);
            dispatch(displayMessage(response.data.message))
            setErrorMessage('')
        //   let auth_user= JSON.stringify(localStorage.setItem('auth_user',response.data.user))
        //    localStorage.setItem('auth_token',auth_user.token)
        localStorage.setItem(
            "auth_user",
            JSON.stringify(response.data.user)
        );
        localStorage.setItem("otp_refresh",0);
              var token = response.data.token;
                    // localStorage.setItem("auth_token", token);
                    SetAuthToken(token);
                    dispatch(loginSuccess());
                    dispatch(setAuthUser(response.data.user));
            
                window.location.hash="/predictor"
             
            }else{
              setMessage('')
              setErrorMessage(response.data.message);
            }
          })
          
          // Handle OTP verification logic here with otpNumber
      } else {
          setMessage("Please enter the complete OTP.");
      }
  };

const resendOTP=()=>{
    const mobile = localStorage.getItem('mobile');
    var formData=new FormData();
    formData.append('mobile',mobile)
    
    server.post('/resend_registeration_otp',formData)
    .then((response)=>{
        dispatch(displayMessage(response.data.message))
    })
}

    return (
        <div className="container-fluid background pt-5">
            <div className="row mt-5">
                <div className="col-md-6">
                    <img src={logo} alt="Logo" className="ml-4 img-fluid logo_predictor my-3" />
                    <h1 className="fw-font pl-5 blue-color">
                        <strong>NEET-PG College<br /> Predictor</strong>
                    </h1>
                    <ul className="college_benefits">
                        <li>Find the most optimal college for you.</li>
                        <li>Identify best college to study.</li>
                        <li>Search for the colleges you are interested in.</li>
                        <li>Get course cutoffs and fees details.</li>
                        <li>Get 1-to-1 personalised guidance.</li>
                    </ul>
                    <img src={doctor_group} className="doctors_group img-fluid" />
                </div>
                <div className="col-md-6 text-left pt-5">
                    <div className="otp-container">
                        <h3>Please enter OTP sent to your mobile number</h3>
                        <h2>+91 {mobile}</h2>

                        <form onSubmit={handleSubmit} id="otp-verification-form">
                            <div className="otp-input-container">
                                {otp.map((data, index) => (
                                    <input
                                        className="otp-input"
                                        type="text"
                                        name="otp"
                                        maxLength="1"
                                        key={index}
                                        value={data}
                                        onChange={(e) => handleChange(e.target, index)}
                                        onFocus={(e) => e.target.select()}
                                    />
                                ))}
                              
                               
                            </div>

                            <div className="otp-resend" onClick={resendOTP}>
                                <span style={{ color: "#233974" }}>Resend OTP</span>
                            </div>

                            <button type="submit" className="otp-submit-btn">
                                VERIFY AND PROCEED
                            </button>
                        </form>

                        {message && <p className="otp-message">{message}</p>}
                         {error_message && <p className='text-danger'>{error_message}</p>}       
                        <div className="otp-terms">
                            By clicking Verify and Proceed, you agree to the 
                            <span> Terms & Conditions </span>and the
                            <span> Privacy Policy</span>.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterOTP;
